import { useMemo } from 'react';

import { DynamicPackageResult, PathTemplatesFragment as PathTemplates } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { toSearchParams } from '@Core/helpers/url';
import { URLQueryParams } from '@Core/url/URLQueryParams';
import { ISO_DATE_FORMAT, utcDate } from '@Dates/dates';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import {
  mapCancellationPolicies,
  stringifyRooms,
} from '@Stores/SearchSelectionStore/getUrlFromSearchSelection';

export const getPandaUrlForOffer =
  (pathTemplates: PathTemplates['panda']) =>
  (
    result: DynamicPackageResult,
    state: Pick<
      SearchSelectionStore,
      'filters' | 'departureAirports' | 'nights' | 'rooms' | 'source'
    >,
  ) => {
    const params = {
      date: utcDate(result.flights.outbound.departureDate, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT),
      boardBasis: state.filters.boardBasis || [],
      masterId: result.hotel.accommodation.id,
      departureAirports: state.departureAirports,
      rooms: stringifyRooms(state.rooms),
      nights: state.nights,
      source: state.source,
      cancellationPolicy: mapCancellationPolicies(state.filters.cancellationPolicies),
      maxFlightStops: state.filters.maxFlightStops?.length
        ? state.filters.maxFlightStops
        : undefined,
      includedCheckedBags: state.filters.includedCheckedBags?.length
        ? state.filters.includedCheckedBags
        : undefined,
      outboundTimeBuckets: state.filters.outboundTimeBuckets?.length
        ? state.filters.outboundTimeBuckets
        : undefined,
      inboundTimeBuckets: state.filters.inboundTimeBuckets?.length
        ? state.filters.inboundTimeBuckets
        : undefined,
    };

    const urlParams = new URLQueryParams(toSearchParams(params));

    return `${pathTemplates}?${urlParams.toString()}`;
  };

const getPandaUrlWithParams =
  (pathTemplates: PathTemplates['panda']) =>
  (
    masterId: string,
    selection: Pick<SearchSelectionStore, 'date' | 'nights' | 'departureAirports' | 'rooms'>,
  ) => {
    const params = {
      masterId,
      date: selection?.date?.format(ISO_DATE_FORMAT),
      nights: selection.nights,
      departureAirports: selection.departureAirports,
      rooms: stringifyRooms(selection.rooms),
    };

    return `${pathTemplates}?${new URLQueryParams(toSearchParams(params)).toString()}`;
  };

export const usePandaPageUrl = () => {
  const { site } = useAppContext();

  return useMemo(
    () => ({
      getPandaUrlForOffer: getPandaUrlForOffer(site.pathTemplates.panda),
      getPandaUrlParams: getPandaUrlWithParams(site.pathTemplates.panda),
    }),
    [site.pathTemplates],
  );
};
